* {
  font-family: "Montserrat", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#image1 {
  background-image: url("images/FullFamily.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

#image2 {
  background-image: url("images/About.png");
  background-size: cover;
  background-position: center;
  background-repeat: space;
  max-width: 100%;
  min-height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

#notfound {
  position: relative;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: black;
}

.selector-for-some-widget {
  box-sizing: content-box;
}

.bg-dark {
  background-color: #0d0e10 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}

.form-control {
  color: black;
  background-color: white;
  border: 2px solid black;
}

.right-border {
  border-right: 2px solid black;
}

.touch {
  margin-left: 19%;
}

.growhover {
  transition: all 0.2s ease-in-out;
}
.growhover:hover {
  transform: scale(1.1);
}

.carousel .slide {
  text-align: left;
}

.carouselExample {
  max-width: 70%;
}
.slide {
  display: flex;
  align-self: center;
}

.react-stars span {
  margin-right: 6px;
}

.productdescr .row h5 span {
  color: orangered;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url("images/bg.webp") no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}

.notfound h2 {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound a {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
  box-shadow: 0px 4px 15px -5px #0046d5;
}

@media only screen and (max-width: 768px) {
  #inputEmail4 {
    margin-top: 16px;
  }

  .carouselExample {
    max-width: 90%;
  }
  .react-stars {
    margin-left: auto;
    margin-right: auto;
  }

  .touch {
    text-align: center;
    margin-left: 0%;
  }
  .form-pad .row .col-md-6 span {
    padding-left: 7px;
  }
  .form-pad .row {
    margin-right: auto;
    margin-left: auto;
  }

  .notfound .notfound-404 {
    height: 142px;
  }
  .notfound .notfound-404 h1 {
    font-size: 112px;
  }
  .right-border {
    border: none;
  }
  .growhover:hover {
    transform: scale(1);
  }
}

@media screen and (max-width: 1000px) {
  #image2 {
    width: 100%;
  }
}
